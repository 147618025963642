import React from "react";
import Box from "@mui/material/Box";
import SfmViewer from "./SfmViewer.js";
// import AudioPresenter from "./audiopresenter/AudioPresenter.js";
import AudioTest from "./AudioTest.js";
import VideoTest from "./VideoTest.js";
import TextViewer from "./TextViewer.js";
import PagesPresenter from "./PagesPresenter.js";
import { useHistory } from "../context/HistoryContext.js";
import * as database from "../utilities/database.js";

const ItemPresenter = React.forwardRef((props, ref) => {
	const history = useHistory();
	console.log("ItemPresenter... ");
	let n = history.state().n; // handle navigation back to here
	if (!n) {
		n = JSON.parse(JSON.stringify(props.node)); // get a copy of the node
		history.replaceState({ ...history.state(), n: n }); // save for later navigation
	}
	const [node, setNode] = React.useState(n);

	const mediaType = node ? node.mediaType : null; // route on mediaType

	const onDone = () => {
		console.log(node.name + " is Done");
		let parts = node.donePath?.split("/");

		let processDone = async (parts) => {
			//TODO: move path to node function to bookkeeping
			let data = await database.getFromDB("cache", "WebCatalog");
			let nextNode = JSON.parse(data);
			let found = null;
			for (let i = 0; i < parts.length; i++) {
				found = null;
				let c = nextNode.contents;
				if (c)
					for (let j = 0; j < c.length; j++) {
						if (parts[i] === c[j].name) {
							found = c[j];
							break;
						}
					}
				if (!found) {
					console.error("done target not found");
					props.onDone(null);
					break;
				}
				nextNode = found;
			}
			if (found) {
				console.log(node.donePath + " found");
				if (found.contents)
					props.onDone(found); // let App handle WC page change
				else {
					// handle another item here
					let state = history.state();
					history.pushState({ page: state.page, node: found });
					setNode(found);
				}
			}
		};
		if (parts && parts[0]) processDone(parts);
	};

	let content;
	if (mediaType) {
		if (mediaType === "sfm" || mediaType === "usx")
			content = <SfmViewer node={node} />;
		else if (mediaType === "mp3" || mediaType === "wma")
			// content = <AudioPresenter node={node} />;
			content = <AudioTest node={node} />;
		else if (mediaType === "mp4" || mediaType === "m3u8")
			content = <VideoTest node={node} />;
		else if (mediaType === "txt") content = <TextViewer node={node} />;
		else if (mediaType === "pages") {
			let contentProps = node.doneLabel
				? {
						node: node,
						doneLabel: node.doneLabel,
						onDone: onDone
					}
				: { node: node };
			content = <PagesPresenter {...contentProps} />;
		} else content = <Box sx={{ mt: 10 }}>Unsupported Media Type {mediaType}</Box>;
	} else content = <Box sx={{ mt: 10 }}>Missing Media Information </Box>;

	return (
		<div {...props} ref={ref}>
			{content}
		</div>
	);
});

export default ItemPresenter;
